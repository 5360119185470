.destinationlist {
    padding: 80px 0;
    background-color: #ececec;
}
.destinationsgrid .destigridimg img {
    height: 250px;
    object-fit: cover;
    transition: all 0.5s ease;
}
.destinationsgrid .destigridimg {
    overflow: hidden;
}
.destinationsgrid {
    background-color: var(--color4);
    margin: 0 0 25px;
}
.destinationsgrid .destigridhead {
    padding: 10px 15px;
    text-align: center;
}
.destinationsgrid .destigridhead h2 {
    font-size: 24px;
    color: var(--color2);
    transition: all 0.5s ease;
}
.destinationsgrid:hover .destigridhead h2 {
    color: var(--color1);
}
.destinationsgrid:hover .destigridimg img {
    transform: rotate(2deg) scale(1.15);
}
.customdesigrid {
    width: 25%;
    flex: 0 0 auto;
}
@media screen and (max-width:1515px) {
    .customdesigrid {
        width: 33.33%;
    }
}
@media screen and (max-width:1100px) {
    .customdesigrid {
        width: 33.33%;
    }
    .destinationsgrid .destigridimg img {
        height: 200px;
    }   
    .destinationsgrid .destigridhead h2 {
        font-size: 20px;
    }     
}
@media screen and (max-width:800px) {
    .destinationsgrid .destigridimg img {
        height: 145px;
    }
}
@media screen and (max-width:767px) {
    .customdesigrid {
        width: 50%;
    }
    .destinationsgrid .destigridhead h2 {
        font-size: 15px;
    }
    .destinationsgrid .destigridimg img {
        height: 125px;
    }
    .destinationlist {
        padding: 40px 0;
    }
}