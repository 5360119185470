.hotdeals {
    padding: 50px 0;
    background-color: #eff1f4;
}
.hotdeals .hotdealsheading h2 {
    font-size: 35px;
    margin: 0;
}
.hotdeals .hotdealsbtns {
    text-align: right;
}
.hotdeals .hotdealsbtns a {
    background-color: var(--color1);
    color: var(--color4);
    padding: 10px 59px 13px;
    font-size: 18px;
    border-radius: 30px;
    transition: all 0.5s ease;
}
.hotdeals .hotdealsbtns a:hover {
    background-color: var(--color2);
}
.newhotdelsgrid {
    box-shadow: 0 0 8px #3c485826;
    border-radius: 5px;
    overflow: hidden;
    padding: 0 0 15px;
    transition: all 0.5s ease;
    margin: 0 0 25px;
    min-height: 674px;
    max-height: 674px;
    background-color: var(--color4);
}
.newhotdelsgrid .hotdealsnewgimg img {
    height: 300px;
    object-fit: cover;
    width: 100%;
    transition: all 0.5s ease;
}
.newhotdelsgrid .hotgridcheading h2 {
    margin: 0;
    font-size: 24px;
    height: 60px;
    overflow: hidden;
}
.newhotdelsgrid .hotgridcheading {
    padding: 10px 15px;
}
.newhotdelsgrid .hotgridcheading h2 a {
    color: var(--color2);
    transition: all 0.5s ease;
}
.newhotdelsgrid .hotgridcheading h2 a:hover {
    color: var(--color1);
}
.newhotdelsgrid .hotgridmeta ul, .newhotdelsgrid .hotgridshort ul {
    list-style: none;
    display: flex;
    column-gap: 9px;
    column-count: 3;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
    padding: 0;
}
.newhotdelsgrid .hotgridmeta ul {
    justify-content: space-between;
    padding: 0;
}
.newhotdelsgrid .hotgridmeta ul li img {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
}
.newhotdelsgrid .hotgridmeta ul li span {
    color: #000000;
}
.newhotdelsgrid .hotgridmeta {
    padding: 0 15px 10px;
}
.newhotdelsgrid .hotgridshort {
    margin: 10px 0 0;
    padding: 0 15px;
}
.newhotdelsgrid .hotgridshort ul li a {
    display: block;
    background-color: #e3e3e3;
    color: #000;
    padding: 5px 10px;
    margin: 0;
    line-height: 1;
    border-radius: 30px;
    transition: all 0.5s ease;
}
.newhotdelsgrid .hotgridshort ul li a:hover {
    background-color: var(--color2);
    color: #ffffff;
}
.newhotdelsgrid .hotgridshort ul li.hotdealshots a {
    background-color: #801f3c;
    color: #ffffff;
}
.newhotdelsgrid .hotgridcontent p {
    margin: 0;
    line-height: 1.5;
    font-size: 14px;
}
.newhotdelsgrid .hotgridcontent, .newhotdelsgrid .hotgridpricebtn {
    padding: 10px 15px 0;
}
.hotgridpricebtn .pricehotgrid small {
    color: #000000;
}
.hotgridpricebtn .pricehotgrid small span {
    color: #ababab;
}
.hotgridpricebtn .pricehotgrid h5 {
    margin: 0;
    color: var(--color1);
    line-height: 1;
}
.newhotdelsgrid .hotgridpricebtn .hotgridpricebtns {
    display: flex;
    column-gap: 15px;
    justify-content: space-between;
    align-items: center;
}
.newhotdelsgrid .hotgridpricebtn .btnshotdel a {
    background-color: var(--color2);
    color: #ffffff;
    display: block;
    padding: 8px 15px;
    border-radius: 30px;
    transition: all 0.5s ease;
}
.newhotdelsgrid .hotgridpricebtn .btnshotdel a:hover {
    background-color: var(--color1);
}
.newhotdelsgrid .hotdealsnewgimg {
    overflow: hidden;
}
.newhotdelsgrid:hover {
    box-shadow: 0 10px 25px #3c485826;
    transform: translateY(-5px);
}
.newhotdelsgrid:hover .hotdealsnewgimg img {
    transform: rotate(2deg) scale(1.15);
}
.hotdealsarea {
    margin: 50px 0 0;
}
.hotdealsarea .swiper-horizontal {
    height: 800px;
}
.hotdealsarea .swiper-pagination {
    left: 50% !important;
    width: unset !important;
    transform: translateX(-50%);
}
.hotdealsarea span.swiper-pagination-bullet {
    height: 20px;
    width: 20px;
    background: #d3d3d4;
    opacity: 1;
}
.hotdealsarea span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #999a9b;
}
.hotdealsarea .swiper-button-next, .hotdealsarea .swiper-rtl .swiper-button-prev {
    right: 35%;
    top: 96.7%;
    z-index: 9;
}
.hotdealsarea .swiper-button-next:after, .hotdealsarea .swiper-rtl .swiper-button-prev:after, .hotdealsarea .swiper-button-prev:after, .hotdealsarea .swiper-rtl .swiper-button-next:after {
    font-size: 20px;
    background-color: var(--color6);
    color: var(--color4);
    border-radius: 100%;
    padding: 10px 15px;
}
.hotdealsarea .swiper-button-prev, .hotdealsarea .swiper-rtl .swiper-button-next {
    left: 35%;
    top: 96.7%;
    z-index: 9;
}
.hotdealsarea .swiper-slide {
    display: flex;
    justify-content: center;
}
.hotdealsdicount {
    width: 100%;
    background-image: url('../../assets/banners.jpg');
    background-position: center;
    background-size: cover;
    padding: 20px;
    background-repeat: no-repeat;
    position: relative;
    max-height: 682px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}
.hotdealsdicount::before {
    content: '';
    background-color: rgb(20 56 92 / 70%);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}
.discountcontetn {
    position: relative;
    text-align: center;
}
.discountcontetn h2 {
    color: var(--color4);
    font-size: 55px;
    line-height: 1.2;
    margin: 0 0 25px;
}
.discountcontetn p {
    font-size: 26px;
    color: var(--color4);
    line-height: 1.3;
}
.discountbtns {
    margin: 25px 0 0;
}
.discountbtns a {
    background-color: var(--color4);
    color: var(--color2);
    font-weight: 600;
    font-size: 25px;
    padding: 0 29px 7px;
    border-radius: 30px;
    transition: all 0.5s ease;
}
.discountbtns a:hover {
    background-color: var(--color1);
    color: var(--color4);
}
@media screen and (max-width:1515px) {
    .newhotdelsgrid .hotdealsnewgimg img {
        height: 240px;
    }
    .newhotdelsgrid {
        min-height: 580px;
        max-height: 580px;
    }
    .hotdealsarea .swiper-horizontal {
        height: 660px;
    }
    .hotdealsarea .swiper-wrapper {
        height: 88%;
    }
}
@media screen and (max-width:1300px) {
    .hotgridpricebtn .pricehotgrid h5 {
        font-size: 17px;
    }
    .newhotdelsgrid .hotgridpricebtn .btnshotdel a {
        font-size: 14px;
    }
}
@media screen and (max-width:1260px) {
    .newhotdelsgrid .hotgridmeta ul li span {
        font-size: 14px !important;
    }  
    .newhotdelsgrid .hotgridmeta ul li img {
        width: 16px;
        height: 16px;
    } 
    .newhotdelsgrid .hotgridcontent {
        padding: 0px 15px 0;
    }
    .hotgridpricebtn .pricehotgrid h5 {
        font-size: 16px;
    }
    .hotgridpricebtn .pricehotgrid h5 {
        font-size: 16px;
    }
}
@media screen and (max-width:1100px) {
    .newhotdelsgrid .hotgridcheading h2 {
        font-size: 17px;
    }
    .newhotdelsgrid .hotgridpricebtn .btnshotdel a {
        font-size: 12px;
    }
}
@media screen and (max-width:800px) {
    .newhotdelsgrid .hotdealsnewgimg img {
        height: 200px;
    }
    .newhotdelsgrid .hotgridmeta ul li {
        width: 31%;
    }
    .newhotdelsgrid .hotgridmeta ul li img {
        width: 14px;
        height: 14px;
        margin: 0 7px 0 0;
    }
    .newhotdelsgrid .hotgridmeta ul li span {
        font-size: 12px;
    }
    .hotgridpricebtn .pricehotgrid h5 {
        font-size: 16px;
    }   
    .newhotdelsgrid .hotgridmeta {
        padding: 0 15px 0px;
    } 
    .newhotdelsgrid .hotgridshort ul li a {
        font-size: 12px;
    }
    .newhotdelsgrid {
        max-height: 560px;
    }
    .hotdealsdicount {
        max-height: 560px;
    }
    .hotdealsarea .swiper-horizontal {
        height: 645px;
    } 
    .hotdealsarea .swiper-pagination {
        display: none;
    } 
    .hotdeals .hotdealsbtns a {
        padding: 6px 28px 10px;
        font-size: 17px;
    }  
    .discountcontetn h2 {
        font-size: 45px;
    }    
}
@media screen and (max-width:767px) {
    .newhotdelsgrid {
        max-height: 100%;
        min-height: auto;
    }
    .hotdeals .hotdealsheading h2 {
        font-size: 25px;
    }
    .searchfiltter .row > .col-lg-8:last-child {
        order: -1 !important;
    }
}
@media screen and (max-width: 600px) {
    .hotdeals .hotdealsbtns a {
        padding: 3px 25px 5px;
        font-size: 13px;
    }
}
@media screen and (max-width: 390px) {
    .newhotdelsgrid .hotgridmeta ul li span {
        font-size: 11px !important;
    }
}