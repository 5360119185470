.country_search {
   background-color: var(--color4);
   padding:0px 20px 20px 20px;
   border-radius:20px;
   box-shadow: 0 0 8px #3c485826;
   position: absolute;
    margin-top: 0px;
    top: 0%;
    left: 0;
    z-index: 20;
    width: 100%;
    max-width: 100vw;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 1;
    background-color: #fff;
    border-top: 1px solid #969696;
    border-radius:20px;
}
.country-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    border-radius: 30px;
    margin-bottom:10px;
    background-color: #f0f0f0;
    transition: background-color 0.3s;
}
.country-item:hover {
    background-color: #f0f0f0;
}
.country-item.active {
    background-color: var(--color2);
    color: #fff;
}

.country-item .flag_icon {
    background-color: var(--color1);
    height: 2rem;
    width: 2rem;
    margin: 0px 0.75rem 0px 0.5rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
}
.country-item .flag_icon svg{fill: var(--color4);}
.country-item .country_name {
    flex-grow: 1;
    font-weight: bold;
}

.country-item.active.flag_icon{
    background-color: var(--color4);
}
.selected-country {
    background-color: var(--color1);
    padding: 5px 10px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    color: var(--color4);
    font-size: 14px;
    font-weight: 600;
}
.btn-reset{
    padding: 5px 15px;
}
.remove-country{
    background-color: transparent;
    border: none;
    color: var(--color4);
    font-weight: bold;
    padding-left: 10px;
}
.btn-apply{
    background-color: var(--color2);
    border:0px;
    color: var(--color4);
    padding:5px 15px;
    border-radius:30px;
    font-size:15px;
    font-weight: 600;
}
.tag-input-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    height: 56px;
    border-radius: 0;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}
.tag {
    background-color: var(--color4);
    padding: 5px 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    color: black;
    font-size: 14px;
}
.tag-input-container input {
    border: none;
    outline: none;
    flex-grow: 1;
    padding: 5px;
    min-width: 100px;
}
.tag-close-btn {
    background: none;
    border: none;
    margin-left: 5px;
    font-size: 16px;
    cursor: pointer;
    color: black;
}
.more-countries{
    min-width: 38px;
    min-height: 38px;
    height: 38px;
    width: 38px;
    margin: 0 .5rem;
    background-color: var(--color2);
    color: var(--color4);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-results-message img{
    width: 150px;
    object-fit: contain;
    height: auto;
}
.country-list{
    max-height:400px;
}