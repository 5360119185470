@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
:root {
  --color1: #b28906;
  --color2: #14385c;
  --color3: #801f3c;
  --color4: #ffffff;
  --color5: #000000;
  --color6: #14385c;
  --color7: #262626;
  --color8: #bbbbbb;
  --font1: "IBM Plex Serif", serif;
}
.plr-50 {
  padding-left: 50px;
  padding-right: 50px;
}
* {
  margin: 0;
  padding: 0;
}
img {
  width: 100%;
}
a {
  text-decoration: none !important;
}
ul, ol {
  margin: 0;
  padding: 0;
}
body {
  font-family: var(--font1) !important;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.5;
}
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font1) !important;
}
.w90 {
  width: 90%;
  margin: auto;
  display: block;
  transition: all 0.5s ease;
}
.no-scroll {
  overflow: hidden;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 600 !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.navarrowprev {
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 35px;
  color: var(--color5);
  line-height: 0;
  transform: translateY(-50%);
}
.navarrownext {
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 35px;
  color: var(--color5);
  line-height: 0;
  transform: translateY(-50%);
}
.blackcolor {
  color: var(--color5);
}
.p0 {
  padding: 0 !important;
}
@media screen and (max-width: 800px) {
  .menuareas {
    display: none;
  }
}
@media screen and (max-width:767px) {
  .navarrowprev {
      left: -24px;
  }
  .navarrownext {
      right: -24px;
  }
}