.mainfooter {
    background-color: var(--color2);
    padding: 80px 0;
}
.footerwidget h2 {
    color: var(--color4);
    font-size: 20px;
    text-transform: capitalize;
}
.footerwidget ul {
    padding: 0;
    margin: 0;
}
.footerwidget ul li a:hover {
    color: var(--color1);
    opacity: 1;
}
.footerwidget ul li a {
    color: var(--color4);
    transition: all 0.5s ease;
    display: block;
    padding: 4px 0;
    opacity: 0.8;
}
.footerwidget {
    margin: 0 0 45px;
}
.footerwidget ul li {
    list-style: none;
}
.footerwidget p {
    color: var(--color4);
}
.footerwidget p a {
    color: var(--color1);
    transition: all 0.5s ease;
}
.footerwidget p a:hover {
    color: var(--color4);
    transition: all 0.5s ease;
}
.socailmediafooter {
    display: flex;
    gap: 15px;
    font-size: 23px;
}
.socailmediafooter a {
    color: var(--color4);
    transition: all 0.5s ease;
}
.socailmediafooter a:hover, .footerbottommenu ul li a:hover {
    color: var(--color1);
}
.footerlogo img {
    width: 200px;
    margin: 0 0 20px;
}
.footerlogo h3 {
    color: var(--color4);
    font-size: 22px;
    margin: 0 0 15px;
}
.footerlogo p {
    font-style: italic;
}
.footerbottom {
    background-color: #1d4f81;
    padding: 15px 0;

}
.copyright p {
    color: var(--color4);
    margin: 0;
}
.footerbottommenu ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    gap: 15px;
}
.footerbottommenu ul li {
    position: relative;
}
.footerbottommenu ul li::after {
    content: '';
    position: absolute;
    right: -7px;
    top: 4px;
    background-color: var(--color4);
    height: 20px;
    width: 2px;
}
.footerbottommenu ul li:last-child:after {
    display: none;
}
.footerbottommenu ul li a {
    color: var(--color4);
    transition: all 0.5s ease;
}
@media screen and (max-width:800px) {
    .copyright p {
        text-align: center;
    }   
    .footerbottommenu ul {
        justify-content: center;
        margin: 6px 0 0;
    }
    .footerwidget.formobiegap {
        margin: 26px 0 30px;
    }
    .footerwidget {
        margin: 0 0 30px;
    }
    .mainfooter {
        padding: 40px 0;
    }
}
@media screen and (max-width: 767px) {
    .copyright p {
        text-align: center;
        font-size: 13px;
    }
    .footerbottommenu ul li {
        font-size: 12px;
    }
    .footerbottommenu ul li::after {
        height: 14px;
    }
    .footerlogo h3 {
        font-size: 16px;
    }
    .footerlogo p {
        font-size: 12px;
    }
    .footerwidget h2 {
        font-size: 14px;
        line-height: 1.5;
    }  
    .footerwidget ul li {
        font-size: 11px;
    }  
    .footerwidget p {
        font-size: 14px;
        margin: 0 0 6px;
    }
}
@media screen and (max-width: 424px) {
    .copyright p {
        text-align: center;
        font-size: 12px;
    }
    .footerbottommenu ul li {
        font-size: 10px;
    }
}