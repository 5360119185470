.mainbanner {
    background-image: url('../../assets/banners.jpg');
    padding: 50px 0 100px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.mainbanner::before {
    content: '';
    background-color: rgb(0 0 0 / 35%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.bannercontent {
    text-align: center;
    position: relative;
    width: 62%;
    margin: auto;
    display: block;
}
.bannercontent h2 {
    color: var(--color4);
    font-size: 50pt;
    line-height: 1.3;
}
.bannercontent p {
    color: var(--color4);
    font-size: 16pt;
    font-weight: 500;
    margin: 0;
}
.bannerbtns {
    margin: 40px 0;
}
.bannerbtns a {
    background-color: var(--color1);
    color: var(--color4);
    padding: 10px 40px 15px;
    font-size: 13.8pt;
    border-radius: 30px;
    border: 3px solid var(--color4);
    transition: all 0.5s ease;
}
.bannerbtns a:hover {
    background-color: var(--color2);
}
.bannerforms form {
    position: relative;
}
.bannerformgoup, .bannerformgoupse {
    position: relative;
}
.bannerformgoupse .form-control {
    padding: 15px 15px 15px 50px;
    border-radius: 0px 30px 30px 0px;
}
.bannerformgoup .form-control {
    padding: 15px 15px 15px 50px;
    border-radius: 30px 0px 0px 30px;
}
.bannerforms form .form-control:focus {
    box-shadow: none;
    outline: none;
}
.bannerformicon {
    position: absolute;
    left: 20px;
    top: 15px;
}
.bannerformicon svg path {
    fill: #5c5c5c;
}
.bannerforms {
    margin: 90px 0 0;
    position: relative;
    z-index: 9;
}
.bannerformsubmit {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
}
.bannerformsubmit button {
    background-color: var(--color1);
    color: var(--color4);
    padding: 9px 35px 12px;
    font-size: 19px;
    border-radius: 30px;
    transition: all 0.5s ease;
    border: none;
}
.bannerformsubmit button:hover {
    background-color: var(--color2);
}
@media screen and (max-width:1300px){
    .bannercontent h2 {
        font-size: 37pt;
    }
}
@media screen and (max-width: 800px) {
    .bannercontent h2 {
        font-size: 25pt;
    }
    .bannercontent p {
        font-size: 14pt;
    }
    .bannerbtns a {
        padding: 6px 30px 11px;
    }
    .bannerforms {
        margin: 53px 0 0;
    }
    .bannerforms {
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .bannercontent {
        width: 100%;
    }
}