.topbararea {
    margin: 0px 0px;
    display: block;
}
.topbararea ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.topheaderleft ul li, .topheaderright ul li {
    font-size: 10.7pt;
    font-weight: 600;
    text-transform: uppercase;
}
.topheaderleft ul, .topheaderright ul {
    display: flex;
    column-gap: 50px;
}
.topheaderleft ul li a, .topheaderright ul li a {
    color: var(--color4);
    transition: all 0.5s ease;
}
.topheaderleft ul li a:hover, .topheaderright ul li a:hover {
    color: var(--color1);
}
.tophearder {
    background-color: var(--color5);
    padding: 15px 0;
}
.topheaderright ul {
    justify-content: end;
}
.topheaderright ul li a svg {
    margin: 0 6px 0 0px;
    height: 1.5em;
    width: 1.5em;
}
.searchbarrightmiddel input, .sidebarsearch input {
    border: 2px solid var(--color5);
    border-radius: 30px;
    padding: 7px 35px 7px 20px;
}
.searchbarrightmiddel .formserarch, .sidebarsearch .formserarch {
    position: relative;
}
.searchbarrightmiddel .formserarch button, .sidebarsearch .formserarch button {
    background-color: var(--color2);
    color: var(--color4);
    height: 40px;
    width: 40px;
    border-radius: 30px;
    line-height: 1;
    position: absolute;
    right: 1px;
    top: 0;
    transition: all 0.5s ease;
    border: none;
}
.searchbarrightmiddel .formserarch button:hover, .sidebarsearch .formserarch button:hover {
    background-color: var(--color1);
}
.searchbarrightmiddel input:focus-visible, .sidebarsearch input:focus-visible {
    border: none;
    border: 2px solid var(--color5);
}
.searchbarrightmiddel input[type=search], .sidebarsearch input[type=search] {
    outline-offset: 0px;
}
.menugrids {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;
}
.menuareas {
    flex-basis: 0;
    flex-grow: 1;
}
.menulogo img {
    width: 145px;
}
.menuareas ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
}
.menuareas ul li a {
    color: #2f3545;
    font-family: var(--font1);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 24px;
    margin: 0 8px;
    padding: 25px 15px;
    text-transform: uppercase;
    transition: all .2s;
    display: block;
}
.menuareas ul li a:hover {
    color: var(--color1);
}
.nav-sticky.mainmenu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 0px 0;
    box-shadow: 0px 0px 99px #a5a5a5;
}
.mainmenu {
    padding: 10px 0;
    background-color: var(--color4);
    transition: all 0.5s ease;
}
.sidebarmenudata button, .sidebargris .sidebarclose {
    background-color: transparent;
    border: none;
    font-size: 25px;
    line-height: 0;
    transition: all 0.5s ease;
}
.sidebarmenudata, .sidebargris .sidebarclose {
    border-right: 2px solid var(--color2);
    padding: 0 30px 0 0px;
}
.sidebarmenudata button:hover, .sidebargris .sidebarclose:hover {
    color: var(--color1);
}
.sidebardata {
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 99;
    background-color: rgb(0 0 0 / 35%);
    bottom: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.32s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.sidebargris {
    background-color: var(--color4);
    max-width: 500px;
    height: 100%;
    position: relative;
    left: -100%;
    visibility: hidden;
    opacity: 0;
    transition: all 0.32s cubic-bezier(0.39, 0.575, 0.565, 1);
    padding: 25px 10px;
}
.sidebardata.sidebaropen .sidebargris, .sidebardata.sidebaropen {
    left: 0;
    opacity: 1;
    visibility: visible;
}
.nav-sticky .w90 {
    width: 100%;
}
.sidebargris h2.sidebartitle {
    position: absolute;
    right: 124px;
    top: 20px;
    font-weight: 600;
    color: var(--color1);
    font-size: 25px;
}
.sidebarsearch {
    margin: 30px 0 0;
    text-align: center;
}
.sidebarsearch .formserarch input{
    width: 100%;
}
.sidebardestination {
    margin: 35px 0 0;
}
.sidebardestilist a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
}
.sidebardestilist .destiicon svg {
    width: 55px;
    height: 55px;
    object-fit: contain;
}
.sidebardestilist {
    border: 2px solid var(--color2);
    border-radius: 10px;
    transition: all 0.5s ease;
    margin: 0 0 25px;
}
.sidebardestilist .destiname h3 {
    color: var(--color5);
    font-weight: 600;
    font-size: 22px;
    transition: all 0.5s ease;
    margin: 0;
}
.sidebardestilist:hover {
    background-color: var(--color2);
}
.sidebardestilist:hover .destiname h3 {
    color: var(--color4);
}
.sidebarscroll {
    max-height: calc(100vh - 76px);
    overflow-y: scroll;
    padding: 10px 46px 0;
}
.sidebardestilist:hover .destiicon svg path {
    fill: var(--color4);
}
.sidebardestilist:hover .destiicon svg path {
    transition: all 0.5s ease;
}
.sidebarsocialmedia {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;
}
.sidebarscroll .sidebarsocialmedia:last-child {
    margin: 0;
}
.socialmediatitle h3 {
    font-size: 18px;
    color: var(--color1);
    margin: 0;
}
.socialicons {
    font-size: 18px;
    color: var(--color5);
    display: flex;
    flex-direction: row;
    gap: 18px;
}
.socialicons a {
    border: 1px solid var(--color5);
    color: var(--color5);
    display: block;
    line-height: 2;
    height: 45px;
    width: 45px;
    border-radius: 100%;
    text-align: center;
    transition: all 0.5s ease;
}
.socialicons a:hover {
    border-color: var(--color1);
    color: var(--color4);
    background-color: var(--color1);
}
.searchreasultarea {
    position: absolute;
    right: 105px;
    top: 147px;
    z-index: 99;
    background-color: var(--color4);
    width: 600px;
    border: 1px solid var(--color1);
    border-radius: 5px;
    padding: 15px;
    max-height: 422px;
    overflow-x: hidden;
}
.regionsearch h2 {
    font-size: 18px;
    margin: 0 0 15px;
}
.regionitem a {
    display: flex;
    column-gap: 5px;
    align-items: center;
}
.regionitem a .regionimg img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 2px solid var(--color1);
}
.regionlist {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-count: 3;
    flex-wrap: wrap;
    column-gap: 10px;
}
.regionitem {
    width: 31.333%;
    margin: 0 0 20px;
}
.regionlist.packagesearch {
    column-count: 2 !important;
}
.regionlist.packagesearch .regionitem {
    width: 48%;
}
.mobiletootle {
    padding: 0;
    border: none;
}
.mobilemenu {
    margin: 30px 0 0;
}
.mobilemenu ul {
    padding: 0;
    list-style: none;
}
.mobilemenu ul li a {
    display: block;
    color: var(--color5);
    margin: 0 0 5px;
    border-bottom: 1px solid #e1e1e1;
    padding: 0 0 7px;
    transition: all 0.5s ease;
}
.mobilemenu ul li a:hover {
    color: var(--color1);
}
.regionitem .regionname span {
    color: var(--color2);
    font-weight: 600;
}
@media screen and (max-width:1300px){
    .topheaderleft ul, .topheaderright ul {
        column-gap: 27px;
    }
    .menuareas ul li a {
        padding: 25px 8px;
    }
    .searchbarrightmiddel {
        display: none;
    }
    .menuareas {
        flex-grow: unset;
    }
}
@media screen and (max-width:800px){
    .topheaderleft ul li, .topheaderright ul li {
        font-size: 7.7pt;
    }
    .topheaderleft ul, .topheaderright ul {
        column-gap: 15px;
    }
    .nav-sticky .w90 {
        width: 90%;
    }
    .menugrids {
        padding: 10px 0;
    }
}
@media screen and (max-width:767px){
    .topheaderleft ul li, .topheaderright ul li {
        font-size: 8.7pt;
    }
    .topheaderleft ul, .topheaderright ul {
        justify-content: center;
    }
    .topheaderright ul li a svg {
        height: 1em;
        width: 1em;
    }
    .topheaderleft {
        margin: 0 0 10px;
    }  
    .sidebargris h2.sidebartitle {
        right: 22px;
    }  
    .sidebardestilist .destiname h3 {
        font-size: 17px;
    }
    .sidebardestilist .destiicon svg {
        width: 40px;
        height: 40px;
    }
    .sidebarsocialmedia {
        flex-wrap: wrap;
    }
    .socialmediatitle {
        width: 100%;
        margin: 0 0 10px;
    }
}
@media screen and (max-width: 420px) {
    .topheaderleft ul li, .topheaderright ul li {
        font-size: 8pt;
    }
    .sidebargris h2.sidebartitle {
        font-size: 22px;
        top: 24px;
    }
}