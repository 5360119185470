.subscibe {
    background-image: url(../../assets/subscription.jpg);
    padding: 100px 0;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.subscibe::before {
    content: '';
    background-color: rgb(0 0 0 / 58%);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}
.subscribebox {
    position: relative;
}
.subscibecontent h2 {
    color: #fff;
    font-size: 50px;
}
.subscibecontent p {
    color: #fff;
    font-size: 22px;
    line-height: 1.5;
}
.subscribeform input {
    width: 100%;
    display: block;
    padding: 10px;
    font-size: 17px;
    border: none;
}
.subscribeform button {
    background-color: var(--color1);
    color: var(--color4);
    margin: 15px 0 0;
    border: none;
    font-size: 20px;
    padding: 6px 40px 11px;
    border-radius: 30px;
    transition: all 0.5s ease;
}
.subscribeform button:hover {
    background-color: var(--color2);
}
@media screen and (max-width:1100px) {
    .subscibecontent h2 {
        font-size: 38px;
    }   
    .subscibecontent p {
        font-size: 17px;
    }     
}
@media screen and (max-width: 767px) {
    .subscibecontent h2 {
        font-size: 30px;
    }
    .subscibecontent p {
        font-size: 15px;
    }
}
@media screen and (max-width: 420px) {
    .subscibecontent h2 {
        font-size: 28px;
    }
}