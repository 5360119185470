.featuredsection {
    padding: 50px 0;
}
.featuredheaing {
    text-align: center;
}
.featuredsection .swiper-horizontal {
    max-width: 90%;
    margin: 0 auto;
}
.featuredsection .swiper-slide {
    display: flex;
    justify-content: center;
}
.faturedbox img {
    height: 76px;
    object-fit: contain;
    width: 76%;
    margin: auto;
    display: block;
}
.featureddata {
    position: relative;
}
@media screen and (max-width:800px) {
    .featuredgridbox {
        margin: 50px 0 0;
    }
    .featuredsection {
        padding: 0px 0 50px;
    }
}