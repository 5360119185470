.instagram {
    padding: 50px 0;
}
.instaheading {
    text-align: center;
    margin: 0 0 45px;
}
.instahead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    margin: auto;
}
.instaimgtag {
    display: flex;
    align-items: center;
    gap: 15px;
}
.instaimgtag .instaimglogo {
    background: linear-gradient(40deg, #f99b4a 15%, #dd3071 50%, #c72e8d 85%);
    width: 90px;
    height: 90px;
    border-radius: 50%;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.instaimgtag .instaimglogo img {
    box-shadow: 0 0 0 5px rgb(255, 255, 255);
    width: 74px;
    height: 74px;
    border-radius: 50%;
    box-sizing: border-box;
    transition: all .2s ease;
}
.instanametag p {
    margin: 0;
}
.instanametag h2 {
    font-size: 25px;
    margin: 0;
}
.instanametag h2 a {
    color: var(--color5);
    transition: all 0.5s ease;
}
.instanametag h2 a:hover, .instanametag p a:hover {
    color: var(--color1);
}
.instanametag p a {
    font-weight: 600;
    color: #878787;
    transition: all 0.5s ease;
}
.instatotalpost, .instafollowbtn {
    text-align: center;
}
.instatotalpost h2 {
    margin: 0;
    font-size: 23px;
}
.instatotalpost p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}
.instafollowbtn a {
    display: block;
    background-color: var(--color1);
    padding: 7px 34px 11px;
    font-size: 17px;
    border-radius: 30px;
    color: var(--color4);
    line-height: 1;
    transition: all 0.5s ease;
}
.instafollowbtn a:hover {
    background-color: var(--color2);
}
.instapost {
    margin: 45px 0 0;
}
.instarow {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0px;
}
.instagbox {
    overflow: hidden;
    position: relative;
}
.instagbox a {
    display: block;
}
.instagbox a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.istafeed {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    opacity: 0;
    visibility: hidden;
}
.istafeed p {
    color: var(--color4);
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    position: relative;    
    bottom: -60px;
    transition: all 0.5s ease;
    opacity: 0;
    visibility: hidden;
}
.instagbox:hover .istafeed {
    opacity: 1;
    visibility: visible;
}
.instagbox:hover .istafeed p {   
    bottom: 0px;
    opacity: 1;
    visibility: visible;
}
.instagramimgbox {
    width: 100%;
    max-width: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;
}
@media screen and (max-width:1300px){
    .instahead {
        width: 84%;
    }
    .instarow {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media screen and (max-width:1024px){
    .instahead {
        width: 92%;
    }
    .instarow {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width:800px){
    .instaimgtag .instaimglogo img {
        box-shadow: 0 0 0 3px rgb(255, 255, 255);
        width: 50px;
        height: 50px;
    } 
    .instaimgtag .instaimglogo {
        width: 60px;
        height: 60px;
    }
    .instanametag h2 {
        font-size: 18px;
    }
    .instanametag p {
        font-size: 14px;
    }
    .instatotalpost h2 {
        font-size: 18px;
    }
    .instatotalpost p {
        font-size: 14px;
    }  
    .instaheading {
        margin: 0 0 25px;
    }  
}
@media screen and (max-width:767px){
    .instahead {
        flex-wrap: wrap;
    }
    .instaimgtag, .instafollowbtn {
        width: 100%;
        justify-content: center;
    }
    .instafollowbtn a {
        display: unset;
    }
    .instahead {
        justify-content: center;
        column-gap: 15px;
    }
    .instafollowbtn {
        margin: 25px 0 0;
    }
    .instaimgtag .instaimglogo img {
        width: 40px;
        height: 40px;
    }
    .instaimgtag .instaimglogo {
        width: 50px;
        height: 50px;
    }
    .instarow {
        grid-template-columns: repeat(2, 1fr);
    }
    .instaimgtag {
        margin: 0 0 14px;
    }
}