.packagelist {
    padding: 0px 0 80px;
}
.packagelistgrid {
    width: 25%;
    flex: 0 0 auto;
}
.packagelistgrid .newhotdelsgrid {
    min-height: 640px;
    max-height: 674px;
}
.searchresult {
    display: flex;
    /* align-items: center; */
    column-gap: 8px;
}
.shoringlist {
    display: flex;
    justify-content: end;
    align-items: center;
    column-gap: 35px;
}
.shortbytext {
    display: flex;
    align-items: center;
    column-gap: 5px;
    background-color: var(--color1);
    border: 2px solid var(--color2);
    padding: 10px 30px;
    border-radius: 30px;
    cursor: pointer;
}
.resultsearch p, .resultsearchounty p {
    margin: 0;
}
.shortbytext p, .filtterbtntext p {
    margin: 0;
    color: var(--color4);
}
.filtterbtntext {
    background-color: var(--color1);
    border: 2px solid var(--color2);
    padding: 10px 30px;
    border-radius: 30px;
    cursor: pointer;
}
.shortbytext {
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.resultsearchounty p, .resultsearch p {
    font-weight: 600;
}
.resultsearchounty {
    border-bottom: 2px solid var(--color1);
    padding: 0 0 5px;
}
.shortarrow {
    color: var(--color4);
    font-size: 16px;
    position: relative;
    top: -1px;
}
.searchfiltter {
    padding: 50px 0;
    position: relative;
}
.fillterrestbtn p {
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: underline;
}
.sortbyfilter {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.shrotbylist {
    position: absolute;
    right: -16px;
    top: 53px;
    z-index: 99;
    left: 16px;
    transition: all 0.2s ease;
    
}
.shrotbylist.isactive, .shrotbylist.closingdiv {
    top: 30px;
    opacity: 0;
}
.shrotbylist .shortlistby ul {
    padding: 0;
    list-style: none;
    margin: 0;
}
.shrotbylist .shortlistby {
    background-color: var(--color4);
    border-radius: 6px;
    box-shadow: 0 35px 35px rgba(0, 0, 0, .2);
    border: 1px solid var(--color2);
}
.shortfilertlistara {
    position: relative;
}
.shrotbylist .shortlistby li {
    padding: 4px 15px;
    cursor: pointer;
    transition: all 0.5s ease;
}
.shrotbylist .shortlistby li:hover {
    color: var(--color4);
    background-color: var(--color1);
}
.shrotbylist .shortlistby li.active {
    background-color: var(--color2);
    color: var(--color4);
}
.filterpopup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: rgb(0 0 0 / 59%);
    bottom: 0;
    z-index: 99;
}
.filterheaidng {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}
.filterpopupdata {
    position: absolute;
    right: -100%;
    top: 0;
    width: 24%;
    background-color: var(--color4);
    bottom: 0;
    padding: 15px;
    transition: all 0.5s ease;
}
.filterheaidng h2 {
    font-size: 23px;
    margin: 0;
}
.popuprest button {
    background-color: transparent;
    border: none;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: underline;
}
.filterpopup.filteropen .filterpopupdata {
    right: 0;
}
.countrylistapi {
    max-height: 500px;
    overflow-x: hidden;
}
.countrylistapi .formgroup {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 0 0 7px;
}
@media screen and (max-width:1680px) {
    .packagelistgrid .hotgridpricebtn .pricehotgrid h5 {
        font-size: 18px;
    }
}
@media screen and (max-width:1515px) {
    .packagelistgrid {
        width: 33.33%;
    }
    .packagelistgrid .newhotdelsgrid {
        min-height: 565px;
        max-height: 565px;
    }
}
@media screen and (max-width:1100px) {
    .packagelistgrid {
        width: 50%;
    }
    .packagelistgrid .newhotdelsgrid {
        min-height: 580px;
        max-height: 580px;
    }
}
@media screen and (max-width:800px) {
    .packagelistgrid .newhotdelsgrid {
        min-height: 530px;
    }
    .shortbytext p, .filtterbtntext p, .fillterrestbtn p, .resultsearchounty p, .resultsearch p {
        font-size: 12px;
    }
    .shortbytext {
        padding: 5px 15px;
    }
    .shoringlist {
        column-gap: 12px;
    }
}
@media screen and (max-width:767px) {
    .packagelistgrid {
        width: 100%;
    }
    .searchfiltter {
        padding: 25px 0 15px;
    }
    .shoringlist {
        margin: 0 0 30px;
    }
    .shortbytext p, .filtterbtntext p, .fillterrestbtn p, .resultsearchounty p, .resultsearch p {
        font-size: 11px;
    }
}