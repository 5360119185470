.servicesarea {
    padding: 50px 0;
}
.serviceicon svg {
    height: 75px;
    width: 75px;
}
.serviceslider .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}
.serviceslider .serviceicon {
    border: 3px solid var(--color1);
    border-radius: 100px;
    height: 150px;
    width: 150px;
    transition: all 0.5s ease;display: flex;
    justify-content: center;
    align-items: center;
}
.servicegrid a {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.serviceslider {
    margin: 44px 0 0;
    position: relative;
}
.serviceheading {
    text-align: center;
    margin: 15px 0 0;
}
.serviceheading h2 {
    color: var(--color5);
    font-size: 26px;
}
.serviceslider .swiper-horizontal {
    max-width: 90%;
    margin: 0 auto;
}
.serviceslider .swiper-button-prev::after, .serviceslider .swiper-button-next::after {
    color: var(--color2);
    font-weight: bold;
}
.servicegrid:hover .serviceicon {
    transform: rotateY(360deg);
    background-color: #b28906;
}
.servicegrid .serviceicon svg path {
    transition: all 0.5s ease;
}
.servicegrid:hover .serviceicon svg path {
    stroke: white !important;
}
.servicegrid.fillcolor:hover .serviceicon svg path {
    fill: var(--color4);
}
@media screen and (max-width:1515px) {
    .serviceheading h2 {
        font-size: 22px;
    }
}
@media screen and (max-width:1100px) {
    .serviceheading h2 {
        font-size: 16px;
    }
    .serviceslider .serviceicon {
        height: 125px;
        width: 125px;
    }
    .serviceicon svg {
        height: 58px;
        width: 58px;
    }    
}