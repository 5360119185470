.breadcrumbarea {
    text-align: center;
    padding: 150px 0;
    background-image: url(../../assets/banners.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.breadcrumtitle {
    position: relative;
}
.breadcrumtitle h2 {
    color: var(--color4);
    font-weight: 900;
    font-size: 60px;
}
.breadcrumbarea::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 32%);
}
.promotiontext {
    background-color: var(--color1);
    text-align: center;
    padding: 10px 0;
}
.promotioncontent p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: var(--color4);
}
@media screen and (max-width:800px) {
    .breadcrumbarea {
        padding: 70px 0;
    }  
    .promotioncontent p {
        font-size: 13px;
    }  
    .breadcrumtitle h2 {
        font-size: 38px;
    }
}
@media screen and (max-width:767px) {
    .breadcrumtitle h2 {
        font-size: 30px;
    }
}