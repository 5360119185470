.whychoose {
    padding: 50px 0 70px;
    background-color: #eff1f4;
}
.whychooseheading h2 {
    margin: 0;
}
.whychooseicon svg {
    height: 75px;
    width: 75px;
}
.whychoosebox {
    margin: 70px 0 0;
    text-align: center;
}
.whychoosetitle h2 {
    font-size: 25px;
    margin: 0;
}
.whychooseicon {
    text-align: center;
    margin: 0 auto 20px;
    width: 150px;
    height: 150px;
    border: 3px solid var(--color5);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
}
.whychoosebox:hover .whychooseicon {
    transform: rotateY(360deg);
    background-color: var(--color1);
    border: 3px solid var(--color1);
}
.whychoosebox .whychooseicon svg path{
    transition: all 0.5s ease;
}
.whychoosebox:hover .whychooseicon svg path{
    fill: var(--color4);
}
.whybtnsbottom {
    text-align: center;
    margin: 80px 0 0;
}
.whybtnsbottom a {
    background-color: var(--color1);
    line-height: 0;
    color: var(--color4);
    padding: 8px 36px 12px;
    font-size: 18px;
    border-radius: 30px;
    transition: all 0.5s ease;
}
.whybtnsbottom a:hover {
    background-color: var(--color2);
}
@media screen and (max-width:1515px) {
    .whychooseicon {
        width: 160px;
        height: 160px;
    }
    .whychooseicon svg {
        height: 80px;
        width: 80px;
    }
}
@media screen and (max-width:1100px) {
    .whychooseicon {
        width: 160px;
        height: 160px;
    }
    .whychooseicon svg {
        height: 75px;
        width: 75px;
    } 
    .whychoosetitle h2 {
        font-size: 20px;
    }   
}
@media screen and (max-width:800px) {
    .whychooseicon {
        width: 125px;
        height: 125px;
    }
    .whychooseicon svg {
        height: 55px;
        width: 55px;
    }
}