.tranding {
    padding: 50px 0;
}
.tranding .trandingheading h2 {
    font-size: 35px;   
    margin: 0;
}
.tranding .trandingbtns {
    text-align: right;
}
.tranding .trandingbtns a {
    background-color: var(--color1);
    color: var(--color4);
    padding: 10px 59px 13px;
    font-size: 18px;
    border-radius: 30px;
    transition: all 0.5s ease;
}
.tranding .trandingbtns a:hover {
    background-color: var(--color2);
}
.trandingdest {
    position: relative;
    height: 250px;
    width: 250px;
    border-radius: 100%;
    border: 4px solid var(--color1);
    overflow: hidden;
    transition: all 0.5s ease;
}
.trandingdest .trandingdestimg {
    overflow: hidden;
}
.trandingdest .trandingdestimg img {
    height: 250px;
    width: 250px;    
    object-fit: cover;
    border-radius: 100%;
    position: relative;
    left: -2px;
    top: -2px;
    transition: all 0.5s ease;
}
.trandingdest .trandingdesthead {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 1;
    text-align: center;
    transform: translateY(-50%);
    transition: all 0.5s ease;
    padding: 0 10px;
}
.trandingdest::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgb(0 0 0 / 32%);
    z-index: 1;
}
.trandingdest .trandingdesthead h2 {
    font-size: 22px;
    color: var(--color4);
    margin: 0;
}
.trandingdest:hover .trandingdesthead {
    top: 38%;
}
.trandingdata {
    margin: 50px 0 0;
}
.trandingdest .traingicons {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -100%;
    z-index: 1;
    text-align: center;
    transition: all 0.5s ease;
}
.trandingdest:hover .traingicons {
    bottom: 0;
    transform: translateY(-59px);
}
.trandingdest .traingicons svg {
    width: 40px;    
    height: 40px;
}
.trandingdest .traingicons svg path {
    fill: var(--color4);
}
.trandingdest:hover .trandingdestimg img {
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
}
.trandingdata .swiper-horizontal {
    height: 375px;
}
.trandingdata .swiper-pagination {
    left: 50% !important;
    width: unset !important;
    transform: translateX(-50%);
}
.trandingdata span.swiper-pagination-bullet {
    height: 20px;
    width: 20px;
    background: #d3d3d4;
    opacity: 1;
}
.trandingdata span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #999a9b;
}
.trandingdata .swiper-button-next, .trandingdata .swiper-rtl .swiper-button-prev {
    right: 45%;
    transform: translateX(146px);
    top: 93%;
    z-index: 9;
}
.trandingdata .swiper-button-next:after, .trandingdata .swiper-rtl .swiper-button-prev:after, .trandingdata .swiper-button-prev:after, .trandingdata .swiper-rtl .swiper-button-next:after {
    font-size: 20px;
    background-color: var(--color6);
    color: var(--color4);
    border-radius: 100%;
    padding: 10px 15px;
}
.trandingdata .swiper-button-prev, .trandingdata .swiper-rtl .swiper-button-next {
    left: 40%;
    transform: translateX(-63px);
    top: 93%;
    z-index: 9;
}
.trandingdata .swiper-slide {
    display: flex;
    justify-content: center;
}

@media screen and (max-width:1515px){
    .trandingdata .swiper-button-prev, .trandingdata .swiper-rtl .swiper-button-next {
        left: 36%;
    }
    .trandingdata .swiper-button-next, .trandingdata .swiper-rtl .swiper-button-prev {
        right: 43%;
    }
    .trandingdest, .trandingdest .trandingdestimg img {
        height: 224px;
        width: 224px;
    }
}
@media screen and (max-width: 1300px) {
    .trandingdata .swiper-button-next, .trandingdata .swiper-rtl .swiper-button-prev {
        right: 40%;
    }
    .trandingdata .swiper-button-prev, .trandingdata .swiper-rtl .swiper-button-next {
        left: 31%;
    }
}
@media screen and (max-width: 800px) {
    .trandingdest .trandingdestimg img, .trandingdest {
        height: 190px;
        width: 190px;
    }
    .trandingdest .trandingdesthead h2 {
        font-size: 18px;
    }
    .trandingdata .swiper-horizontal {
        height: 280px;
    }
    .tranding .trandingheading h2 {
        font-size: 28px;
    }
    .trandingdata .swiper-button-next, .trandingdata .swiper-rtl .swiper-button-prev {
        right: 38%;
        top: 91%;
    }
    .trandingdata .swiper-button-prev, .trandingdata .swiper-rtl .swiper-button-next {
        left: 25%;
        top: 91%;
    }
    .tranding .trandingbtns a {
        padding: 6px 28px 10px;
        font-size: 17px;
    }
}
@media screen and (max-width: 600px) {
    .tranding .trandingheading h2 {
        font-size: 15px;
    }
    .trandingdata .swiper-pagination {
        display: none;
    }
    .trandingdata .swiper-button-next, .trandingdata .swiper-rtl .swiper-button-prev {
        right: 35%;
        transform: translateX(50%);
    }
    .trandingdata .swiper-button-prev, .trandingdata .swiper-rtl .swiper-button-next {
        left: 25%;
        transform: translateX(50%);
    }
    .trandingdest .trandingdestimg img, .trandingdest {
        height: 155.5px;
        width: 155.5px;
    }
    .trandingdata .swiper-horizontal {
        height: 235px;
    }
    .tranding .trandingbtns a {
        padding: 3px 25px 5px;
        font-size: 13px;
    }
    .trandingdata .swiper-button-prev, .trandingdata .swiper-rtl .swiper-button-next {
        left: 32%;
    }
    .trandingdata .swiper-button-next, .trandingdata .swiper-rtl .swiper-button-prev {
        right: 38%;
        transform: translateX(50%);
    }
}
@media screen and (max-width: 390px) {
    .trandingdest .trandingdestimg img, .trandingdest {
        height: 135px;
        width: 135px;
    }
    .newhotdelsgrid .hotgridmeta ul li span {
        font-size: 11px !important;
    }
}