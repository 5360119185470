.testimonails {
    background-image: url(../../assets/tstimonial.png);
    padding: 50px 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.testimonails::before {
    content: '';
    background-color: rgb(0 0 0 / 58%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.testimonailheaidng {
    position: relative;
}
.testimonailheaidng h2 {
    color: var(--color4);
}
.testimonaildata {
    position: relative;
    margin: 60px 0 0;
}
.testimonialbox {
    background-color: var(--color4);
    padding: 30px;
    border-radius: 5px;
    min-height: 240px;
}
.testimonialstar {
    display: flex;
    color: var(--color1);
    font-size: 23px;
    gap: 5px;
    margin: 0 0 15px;
}
.reviewcontetn p {
    margin: 0;
}
.reviername h2 {
    margin: 0 0 15px;
    font-size: 25px;
    color: var(--color2);
}
.testimonaildata .swiper-pagination {
    left: 50% !important;
    width: unset !important;
    transform: translateX(-50%);
}
.testimonaildata .swiper-horizontal {
    height: 340px;
}
.testimonaildata span.swiper-pagination-bullet {
    height: 20px;
    width: 20px;
    background: var(--color4);
    opacity: 0.3;
}
.testimonaildata span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--color4);
}
.navarrowprevbottom {
    position: absolute;
    bottom: 6px;
    left: 40%;
    color: var(--color2);
    font-size: 25px;
    background-color: var(--color4);
    line-height: 1.2;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    text-align: center;
    transition: all 0.5s ease;
    z-index: 9;
}
.navarrowprevbottom:hover, .navarrownextbottom:hover {
    background-color: var(--color1);
}
.navarrownextbottom {
    position: absolute;
    bottom: 6px;
    right: 40%;
    color: var(--color2);
    font-size: 25px;
    background-color: var(--color4);
    line-height: 1.2;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    text-align: center;
    transition: all 0.5s ease;
    z-index: 9;
}
.testimonaildata .swiper-wrapper {
    height: auto !important;
}
.testimonialbtns {
    position: relative;
    text-align: center;
    margin: 80px 0 0;
}
.testimonialbtns a {
    background-color: var(--color1);
    line-height: 0;
    color: var(--color4);
    padding: 8px 36px 12px;
    font-size: 18px;
    border-radius: 30px;
    transition: all 0.5s ease;
}
.testimonialbtns a:hover {
    background-color: var(--color2);
}
.reviewcontetn {
    height: 125px;
    overflow-x: hidden;
}
@media screen and (max-width:1515px) {
    .navarrowprevbottom {
        left: 35%;
    }
    .navarrownextbottom {
        right: 35%;
    }
}
@media screen and (max-width:1100px) {
    .testimonialbox {
        padding: 15px;
    }
    .testimonialstar {
        font-size: 18px;
    }   
    .reviername h2 {
        font-size: 19px;
    } 
    .reviewcontetn p {
        font-size: 14px;
    }
    .testimonaildata .swiper-pagination {
        display: none;
    }
    .testimonialbtns {
        margin: 60px 0 0;
    }
}
@media screen and (max-width:767px) {
    .navarrowprevbottom {
        left: 22%;
    }
    .navarrownextbottom {
        right: 22%;
    }    
    .navarrownextbottom {
        right: 30%;
    }    
    .navarrowprevbottom {
        left: 30%;
    }
}